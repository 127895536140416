import { createContext, Dispatch, useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom';
import { RouteConfig } from './routes/route-config';
import { DialogsParams } from './components/dialog/dialog.type';
import { useDialog } from './hooks/use-dialog';
import { Dialog } from './components/dialog/dialog';
import { EventManager } from './event/event-manager';
import { EventInitialize } from './initializer/event-initiarize';
import { ResponseGetUserDetail } from './api/routes/users/detail';
import { ResponseLogin } from './api/routes/login';

export const AppContext = createContext({} as {
  dialogAction: DialogsParams,
  user: ResponseLogin | null,
  setUser: Dispatch<React.SetStateAction<ResponseLogin | null>>,
});

function App() {
  const dialogAction = useDialog();
  const [user, setUser] = useState<ResponseLogin | null>(null);

  useEffect(() => {
    if (!document.title.includes(process.env.REACT_APP_VERSION_UNIX ?? '')) {
      document.title = `${document.title} - v${process.env.REACT_APP_VERSION_UNIX}`
    }
  }, []);
  return (
    <AppContext.Provider value={{ dialogAction, user, setUser }}>
      <HashRouter>
        <EventInitialize />
        <RouteConfig />
        <Dialog />
      </HashRouter>
    </AppContext.Provider>
  );
}

export default App;
