const ErrorToken = () => {
  return (
    <>
        <div className="dialog_wrap direct_access add_back">
          <div className="dialog">
            <header>このURLは無効です</header>
            <div className="dialog_body">
              <div className="inner">
                <div className="text_box">
                  このURLは既に使用されたか、無効なURLです。<br />
                  再招待を依頼してください。
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default ErrorToken;
