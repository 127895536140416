import { fullUrl, RequestBaseType, apiAction, ReturnResBase } from "../utile";

const path = '/models';

export type RequestPostModel = {
    /** 機種マスタファイル */
    File: File;
}

export type ResponseModels = {
    /**ID*/
    id: number;
    /**機種ファイル名*/
    name: string;
    /**バージョン値*/
    version: string;
    /**登録日時*/
    created_at: string;
    /**最終更新日時*/
    updated_at: string;
}

const models = (url: string) => () => {
    const full = fullUrl(url, path);
    return({
        get: (param: RequestBaseType<{}, ReturnResBase<ResponseModels>>) => apiAction({
            url: full,
            method: 'get',
            ...param,
        }),
        post: (param: RequestBaseType<RequestPostModel, ReturnResBase<{}>>) => {
          const formData = new FormData();
          formData.append('Filedata' , param.data?.File!);
          return apiAction({
            url: `${full}/upload`,
            method: 'post',
            ...param,
            data: formData,
          });
        },  
    })
}

export default models;