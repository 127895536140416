import { IConnection } from "./connection/connection-base";
import { ConnectionForClient } from './connection/connection';
import axios from "axios";
import login from './routes/login';
import logout from "./routes/logout";
import islogin from "./routes/islogin";
import users from "./routes/users";
import models from "./routes/models";
import flyers from "./routes/flyers";
import auth from "./routes/auth";
import activate from './routes/activate/index';
import logos from "./routes/logos";

export type Mode = 'local' | 'develop' | 'stage' | 'production';

type Param = {
  mode: Mode,
};


export default class API {
  static con: IConnection = ConnectionForClient;
  static token: string = '';
  static isLogin: boolean = false;
  /* アプリ側API host */
  private readonly host: string;
  /* 管理側API host */
  private readonly adminHost: string;
  constructor() {
    const hostName = window.location.hostname;
    const modificationPath = '/api/v1';
    if (hostName === 'localhost') {
      this.host = `https://isp-maps.marietta.dev${modificationPath}`;
      this.adminHost = `https://isp-maps.marietta.dev/admin${modificationPath}`
    } else {
      this.host = `https://${hostName}${modificationPath}`;
      this.adminHost = `https://${hostName}/admin${modificationPath}`;
    }
  }

  public connect = () => ({
    login: login(this.adminHost),
    logout: logout(this.adminHost),
    islogin: islogin(this.adminHost),
    users: users(this.adminHost),
    models: models(this.adminHost),
    flyers: flyers(this.adminHost),
    logos: logos(this.adminHost),
    auth: auth(this.host),
    activate: activate(this.host),
  })
}

export const getTokenFromLocalStorage = ():string => {
  return localStorage.getItem('token') ?? '';
}

export const saveTokenToLocalStorage = (token?: string) => {
  if (token) {
    localStorage.setItem('token', token);
  }
}

export const deleteToken = () => {
  localStorage.removeItem('token');
}

export const createPostHeaders = () => {
  return { 
    'X-CSRF-TOKEN': getTokenFromLocalStorage(),
 }
}

export const Api = new API()