import { useCallback, useEffect } from 'react';
import iconEnvelope from '../../assets/images/icon_mail.svg';



type SendMailDialogProps = {
  message: string[],
  callback: () => void,
};

export const SendMailDialog = (props: SendMailDialogProps) => {
  const { message, callback } = props;
  const handlerClickBody = useCallback(() => {
    document.body.removeEventListener('click', handlerClickBody);
    callback();
  }, []);
  useEffect(() => {
    document.body.addEventListener('click', handlerClickBody);
  }, []);
  return (
    <div className="for_send_mail">
      <img src={iconEnvelope} alt="" />
      <div className="text_box">
        {message.map((v, i) => (
          <div key={`send-mail-dialog-message_${i}`}>{v}</div>
        ))}
      </div>
    </div>
  );
};

