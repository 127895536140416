import { fullUrl, RequestBaseType, apiAction, ReturnResBase } from "../utile";

const path = '/islogin';

export type IsLoginPostResponse = {
  user_id: string,
}

const islogin = (url: string) => () => {
  const full = fullUrl(url, path);
    return({
      post: (param: RequestBaseType<{}, ReturnResBase<IsLoginPostResponse>>) => apiAction({
        url: full,
        method: 'get',
        ...param,
    }),
  })
}

export default islogin;