import { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { Api } from "../../../api";
import { ResponseGetFlyers } from "../../../api/routes/flyers";
import { DateFormatter } from "../../../lib/date-formatter";
import { Button } from "../button/button";
import { DropZone } from "../drop-zone/drop-zone";
import { SideBar } from "../sidebar/sidebar"
import { Table, TableBodyProps } from "../table/table";
import "./ledger.scss";
import { useOnesDidMount } from '../../../hooks/life-cycle';
import { AppContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { RoutingPath } from '../../../routes/routing-path';
import { FileSizeFormatter } from '../../../lib/file-size-formatter';

type Props = {
  title: string,
  head?: string[],
  apiRoute: 'logos' | 'flyers',
  limit?: {
    size: number,
    siPrefix?: 'K' | 'M' | 'G',
  }
}

export const Ledger = (props: Props) => {
  const {
    title,
    head,
    apiRoute,
    limit,
  } = props;

  const { dialogAction, user } = useContext(AppContext);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [dataList, setDataList] = useState<ResponseGetFlyers[]>([]);
  const [currentFile, setCurrentFile] = useState<File | null>(null);


  const getList = useCallback(() => {
    Api.connect()[apiRoute]().get({
      onSuccess: (res) => {
        setDataList(res.data.body.data);
      }
    })
  }, [apiRoute]);

  const upload = useCallback(async() => {
    if (!currentFile) return;
    const pushCheck = () => new Promise<boolean>((resolve) => {
      dialogAction.pushMessage({
        title: `${title}アップロード`,
        message: [
          `${currentFile.name}をアップロードします。`,
          `よろしいですか？`,
        ],
        buttons: [
          { label: 'キャンセル', callback: () => resolve(false), color: 'tertiary' },
          { label: '登録', callback: () => resolve(true) },
        ],
      })
    });
    const check = await pushCheck();
    dialogAction.pop();
    if (!check) return;
    setLoad(true);
    dialogAction.pushLoading();
    Api.connect()[apiRoute]().post({
      data: {
        File: currentFile,
      },
      popLoading: true,
      onSuccess: (res) => {
        dialogAction.pushMessage({
          title: `${title}アップロード`,
          message: [
            'アップロードが完了しました。',
          ],
          buttons: [
            { label: 'OK', 
              callback: () => {
                dialogAction.pop();
                getList();
              }
            },
          ],
        });
        setCurrentFile(null);
      },
      onFinally: () => setLoad(false),
    })
  }, [currentFile]);

  const deleteFile = useCallback(async(id: string, name: string) => {
    const check = await dialogAction.pushAsyncCheckMessage({
      title: '確認',
      message: [
        `${name}を削除します。`,
        'よろしいですか？'
      ],
      buttons: {
        ok: { label: '削除' },
        ng: { label: 'キャンセル' },
      }
    })
    if (!check) return;
    dialogAction.pushLoading();
    Api.connect()[apiRoute]().detail(id).delete({
      popLoading: true,
      onSuccess: () => {
        dialogAction.pushMessage({
          title: `${title}削除`,
          message: [
            '削除が完了しました。',
          ],
          buttons: [
            { label: 'OK', 
              callback: () => {
                dialogAction.pop();
                getList();
              }
            },
          ],
        });    
      }
    });
  }, [apiRoute, dialogAction]);

  const tableBody: TableBodyProps[] = useMemo(() => {
    const _data = dataList.map((data) => ({
      data: [
        data.filename,
        DateFormatter.str2str(data.updated_at, 'YYYYMMDD', '/') ?? '',
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button label="削除" onClick={() => deleteFile(data.id, data.filename)} style={{ minWidth: '44px' }} />
        </div>
      ],
      tdPadding: ['', '0px', '0px'],
      tdWidth: ['60%', '25%', '15%'],
    }))
    return _data
  }, [dataList]);

  const onLoadFile = useCallback((file: File) => {
    if (limit && (FileSizeFormatter(file.size, 'M') > limit.size)) {
      dialogAction.pushMessage({
        title: '確認',
        message: [
          <>
            <span>指定されたファイルが</span>
            <span style={{ color: '#e53e3e' }}>{`${limit.size}MB`}</span>
            <span>を超過しています。</span>
          </>
        ],
        buttons: [
          { label: 'OK', callback: () => dialogAction.pop() },
        ]
      })
      return;
    }
    setCurrentFile(file);
  }, []);

  useOnesDidMount(() => {
    getList();
  });

  useEffect(() => {
    if (user && !user.is_admin_ope3) {
      navigate(RoutingPath.user);
    }
  }, [user]);
  
  return (
    <div id="App">
      <SideBar />
      <section className="ledger_container">
        <header className="header_wrap">
          <div className="title_wrap">
            <h2 className="title">{`${title}入替`}</h2>
          </div>
        </header>
        <div className="ledger_body_container">
          <div className="ledger_body_main_cnt">
            <DropZone
              label={title}
              title={`${title}をアップロードします。`}
              accept=""
              callback={(file) => onLoadFile(file)}
              limit={limit}
            />
            <div className="file_name_container">
              選択したファイル：{currentFile?.name ?? ''}
            </div>
            <div className='button_area'>
              <Button
                size="large"
                color="tertiary"
                label="キャンセル"
                disabled={!currentFile || load}
                onClick={() => setCurrentFile(null)}
              />
              <Button
                size="large"
                label="アップロード"
                disabled={!currentFile || load}
                onClick={() => upload()}
              />
            </div>
            <div className="table_container">
            <Table
              head={head ?? []}
              body={tableBody ?? []}
              alignList={['l', 'c', 'c']}
              alignBodyList={['l', 'c', 'c']}
              setSortBy={() => {}}
              setHighlow={() => {}}
            />
          </div>
          </div>
        </div>
      </section>
    </div>
  )
}