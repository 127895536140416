import { Ledger } from "../../ui/ledger/ledger"
import { FileInfoCollection } from '../../../collection/file-info-collection';

export const Flyer = () => {
  return (
    <Ledger
      title="チラシ"
      apiRoute="flyers"
      head={['現在のチラシ', '更新日', '']}
      limit={{
        size: FileInfoCollection.LIMIT.flyer,
        siPrefix: 'M',
      }}
    />
  )
}