import React, { useEffect } from 'react';

export type PagerProps = {
  totalPage: number, // 総ページ数
  indexNum?: number, // 一画面に表示するページネーションの目次数
  currentPage: number, // 現在のページ　
  callback: (page: number) => void,
  // setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  onChange: (page: number) => void,
};

export const Pager = (props: PagerProps) => {
  const { totalPage, indexNum, currentPage, callback, onChange } = props;

  const _indexNum = indexNum === undefined ? 5 : indexNum; // デフォルト値の設定

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage]);

  const pages = [];
  for (let i = 0; i <= totalPage; i++) {
    pages.push(i);
  }

  let startIndex, endIndex; // 目次の最初と最後

  // 現在のページ数に合わせて表示制御
  if (totalPage < _indexNum) {
    startIndex = 0;
    endIndex = totalPage;
  } else {
    if (currentPage <= Math.ceil(_indexNum / 2)) {
      startIndex = 0;
      endIndex = _indexNum - 1 ;
    } else if (currentPage + Math.floor(_indexNum / 2) >= totalPage) {
      startIndex = totalPage - (_indexNum - 1);
      endIndex = totalPage;
    } else {
      startIndex = currentPage - Math.floor(_indexNum / 2);
      endIndex = currentPage + Math.floor(_indexNum / 2);
    }
  }

  const handleBack = (): void => {
    callback(currentPage - 1);
  };
  const handleForward = (): void => {
    callback(currentPage + 1);
  };
  const handleMove = (page: number): void => {
    callback(page);
  };
  const handleTop = (): void => {
    callback(0);
  };
  const handleLast = (): void => {
    callback(totalPage);
  };

  const pageNumbers = [];

  for (let i = startIndex; i <= endIndex; i++) {
    pageNumbers.push(
      <button
        key={i}
        onClick={() => handleMove(i)}
        className={currentPage === i ? 'now' : ''}
      >
        {String(i + 1)}
      </button>,
    );
  }

  return (
    <div className="pager">
      {totalPage >= 0 && (
        <>
          <button
            onClick={() => handleTop()}
            className={currentPage ? '' : 'now'}
          >
            &lt;&lt;
          </button>
          <button
            onClick={() => handleBack()}
            className={currentPage ? '' : 'now'}
          >
            &lt;
          </button>
          {pageNumbers.map(page => {
            return page;
          })}
          <button
            onClick={() => handleForward()}
            className={currentPage === totalPage ? 'now' : ''}
          >
            &gt;
          </button>
          <button
            onClick={() => handleLast()}
            className={currentPage === totalPage ? 'now' : ''}
          >
            &gt;&gt;
          </button>
        </>
      )}
    </div>
  );
};
