import { AxiosResponse } from "axios";
import API, { saveTokenToLocalStorage } from "..";
import { ConnectionOption, ConnectionRequest } from "../connection/connection-base";
import { EventManager } from '../../event/event-manager';
import { MessageDialogData } from "../../components/dialog/dialog.type";

export type ReturnResBase<T = {}, P = {}> = {
  body: {
    data: T,
  }
} & P;

export type RequestGetListBase<T = {}> = {
  /** 並替基準列 */
  sort_by?: number;
  /** 並替方法 */
  highlow?: number;
} & T;

export type RequestBaseType<T, P> = {
  onSuccess?: (res: AxiosResponse<P>) => void,
  onError?: (e: any, otherDialog?: boolean) => void,
  onFinally?: () => void,
  errorProps?: MessageDialogData,
  ignoreErrorDialog?: boolean,
  data?: T,
  headers?: any,
  option?: ConnectionOption,
  popLoading?: boolean,
}

export type ApiActionParam<T, P> = {
  url: string,
  method: keyof typeof API.con,
} & RequestBaseType<T, P>;

export const fullUrl = (origin: string, path: string) => `${origin}${path}`;

export const apiAction = <T, P>(param: ApiActionParam<T, P>) => {
  const { url ,onSuccess, onError, data, method, onFinally, headers: _headers, option, popLoading } = param;
  if (method === 'stop') return;
  const headers = (method === 'post' || method === 'delete') ? { ..._headers, 'X-CSRF-TOKEN': API.token } : _headers;
  API.con[method]<T, P>({ url, data, headers }, option)
  .then((res) => {
    if (popLoading) EventManager.ins.emit('pop-message');
    const token = res.headers['client-security-token'];
    if (token) API.token = token;
    onSuccess?.(res);
  }).catch((e) => {
    if (popLoading) EventManager.ins.emit('pop-message');
    let viewDialog = false;
    const errorCode = e?.response?.status;
    let isError = false;
    if (!param.ignoreErrorDialog) {
      if (errorCode === 401) {
        const status = window.performance.navigation.type;
        if (API.isLogin || (status === 1)) {
          viewDialog = true;
          EventManager.ins.emit('push-unique-message', {
            id: 'auth-error',
            title: `エラー${errorCode ? ` (CODE：${errorCode})` : ''}`,
            message: ['セッションが切断されました。', 'ログイン画面に遷移します'],
            buttons: [
              { label: 'CLOSE', callback: () => {
                EventManager.ins.emit('pop-message');
                window.location.href = '/#/';
              }},
            ],
          });
        } else {
          window.location.href = '/#/';
        }
      } else if (e?.response?.data?.body?.data?.errors && Object.keys(e?.response?.data?.body?.data?.errors)?.length && !param.errorProps) {
        isError = true;
        viewDialog = true;
        const keys = Object.keys(e.response.data.body.data.errors) as any;
        const values: string[] = [];
        keys.forEach((v: any) => {
          const value = e.response.data.body.data.errors[v];
          if (value instanceof Array<string>) {
            values.push(...value);
          }
        });
        EventManager.ins.emit('push-message', {
          title: `エラー${errorCode ? ` (CODE：${errorCode})` : ''}`,
          message: [...values],
          buttons: [
            { label: 'OK', callback: () => EventManager.ins.emit('pop-message')},
          ],
        });
      }
      if (param.errorProps) {
        viewDialog = true;
        EventManager.ins.emit('push-message', param.errorProps);
      }
    }
    if ((!errorCode || (errorCode === 500)) && !isError) {
      viewDialog = true;
      EventManager.ins.emit('internal-server-error');
    }
    onError?.(e, viewDialog);
  }).finally(() => {
    onFinally?.();
  })
}