import { apiAction, fullUrl, RequestBaseType, RequestGetListBase, ReturnResBase } from '../utile';
import detail from './detail';
const path = '/logos';

export type ResponseGetLogosDataEntity = {
  /** チラシID */
  id: string;
  /** ファイル名 */
  filename: string;
  /** バージョン値 */
  version: string;
  /** 作成日時 */
  created_at: string;
  /** 最終更新日時 */
  updated_at: string;
}

export type RequestPostLogos = {
  /** チラシファイル */
  File: File;
}

const logos = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: (param: RequestBaseType<RequestGetListBase, ReturnResBase<ResponseGetLogosDataEntity[]>>) => apiAction({
      url: full,
      method: 'get',
      ...param,
    }),
    post: (param: RequestBaseType<RequestPostLogos, {}>) => {
      const formData = new FormData();
      formData.append('Filedata' , param.data?.File!);
      return apiAction({
        url: `${full}/upload`,
        method: 'post',
        ...param,
        data: formData,
      });
    },
    detail: detail(full),
  })
}

export default logos;