import { useCallback, useEffect, useState } from "react";
import { RequestGetUsers } from "../../../api/routes/users";
import { ReplaceEisu } from "../../../lib/replace-eisu";
import { Accordion } from "../../ui/accordion/accordion"
import { Input } from "../../ui/input/input";
import { LabeledForm } from "../../ui/input/labeled-form";
import "./search-box.scss";

type Props = {
  getList: (param: RequestGetUsers) => void,
}

export const SearchBox = (props: Props) => {
  const { getList } = props;

  /* - state - */
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [companyName, setCompanyName] = useState('');

  /* - callback - */
  const changeName = useCallback((v: string) => {
    setName(v);
  }, []);
  const changeAddress = useCallback((v: string) => {
    setAddress(ReplaceEisu(v));
  }, []);
  const changeCompanyName = useCallback((v: string) => {
    setCompanyName(v);
  }, []);
  
  return (
    <div className="search_box_wrap">
      <div className="search_box">
        <Accordion
          summary="検索条件"
          contents={(
            <div className="search_condition_box">
              <div className="search_condition_box__row">
                <LabeledForm
                  label="名前"
                  formEle={<Input
                    placeholder=""
                    value={name}
                    onChange={(e) => changeName(e.target.value)}
                    onBlur={(e) => getList({ full_name: e.target.value })}
                  />}
                  className="free_word"
                />
                <LabeledForm
                  label="アドレス"
                  formEle={<Input
                    placeholder=""
                    value={address}
                    onChange={(e) => changeAddress(e.target.value)}
                    onBlur={(e) => getList({ email: e.target.value })}
                  />}
                  className="free_word"
                />
                <LabeledForm
                  label="会社名"
                  formEle={<Input
                    placeholder=""
                    value={companyName}
                    onChange={(e) => changeCompanyName(e.target.value)}
                    onBlur={(e) => getList({ company_name: e.target.value })}
                  />}
                  className="free_word"
                />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  )
}