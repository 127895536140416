import { useEffect, useRef } from 'react';

export const useDidMount = (func: () => void) => useEffect(() => { func(); }, []);

export const useWillUnMount = (func: () => void) => useEffect(() => func, []);

export const useOnesDidMount = (func: () => void) => {
  const init = useRef<boolean | null>(false);
  return useEffect(() => {
    if (!init.current) {
      init.current = true;
      func();
    }
  }, []);
}
