import { fullUrl, RequestBaseType, apiAction, ReturnResBase } from "../utile";

const path = '/login';

export type RequestLogin = {
  /** メールアドレス */
  email:	string,
  /** パスワード */
  password:	string,
}

export type ResponseLogin = {
  /** ユーザーID */
  user_id:	string,
  /** メールアドレス */
  email:	string,
  /** 会社名 */
  company_name:	string,
  /** 管理操作権限－ユーザー管理 */
  is_admin_ope1:	number,
  /** 管理操作権限－機種マスタ管理 */
  is_admin_ope2:	number,
  /** 管理操作権限－帳票資料管理 */
  is_admin_ope3:	number,
  /** 姓（漢字） */
  family_name:	string,
  /** 名（漢字） */
  name:	string,
  /** 姓（カナ） */
  family_name_kana:	string,
  /** 名（カナ） */
  name_kana:	string,
  /** 権限 1:管理 2:一般 3:ゲスト */
  role: number,
}

const login = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: (param: RequestBaseType<RequestLogin, ReturnResBase<ResponseLogin>>) => apiAction({
      url: full,
      method: 'post',
      ...param,
    }),
  })
}

export default login;
