import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../ui/button/button';
import { RoutingPath } from '../../../routes/routing-path';
import { useDidMount } from '../../../hooks/life-cycle';
import { ResponsePostAuth } from '../../../api/routes/auth';

export type ActivateEditData = {
  name: string,
  familyName: string,
  nameKana: string,
  familyNameKana: string,
}

type Props ={
  onBack: () => void,
  onSubmit: () => Promise<void>,
  data: ActivateEditData,
}  

export const ActivateCheck = (props: Props) => {
  const { onBack, onSubmit, data } = props;
// -------------------- state --------------------
  const [isDisabled, setIsDisabled] = useState(false);

  const onClickSubmit = useCallback(() => {
    setIsDisabled(true);
    onSubmit().then(() => {
      setIsDisabled(false);
    });
  }, []);

  return (
    <div className="dialog_wrap direct_access check">
      <div className="dialog">
        <header>
          入力内容確認
        </header>
        <div className="dialog_body">
          <div className="edit_wrap">
            <div className="edit_box">
              <div className="item_wrap">
                <div className="item_head">ユーザー名</div>
                <div className="item_cnt">
                    <label className="text_box">
                      {data.familyName}&nbsp;{data.name}
                    </label>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_head">ユーザー名（カナ）</div>
                <div className="item_cnt">
                  <label className="text_box">
                    {data.familyNameKana}&nbsp;{data.nameKana}
                  </label>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_head">パスワード</div>
                <div className="item_cnt">
                  <label className="text_box">
                    ●●●●●●●●
                  </label>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <Button
              size="large"
              color="tertiary"
              label={'戻る'}
              onClick={onBack}
              disabled={isDisabled}
            />
            <Button
              size="large"
              color="primary"
              label={'登録'}
              disabled={isDisabled}
              onClick={() => onClickSubmit()}
            />
          </footer>
        </div>
      </div>
    </div>
  );
};
