import { useEffect, useContext } from 'react';
import { AppContext } from '../App';
import { EventManager } from "../event/event-manager";

export const EventInitialize = () => {
  const { dialogAction } = useContext(AppContext);
  useEffect(() => {
    EventManager.ins.on('internal-server-error', () => {
      dialogAction.pushUniqueMessage({
        id: 'internal-server-error-dialog',
        title: '確認',
        message: [
          'ネットワークエラーが発生しました。',
          '通信環境をご確認ください。',
        ],
        buttons: [
          { label: 'OK', callback: () => dialogAction.pop()},
        ],
      })
    });
    EventManager.ins.on('push-message', (props) => dialogAction.pushMessage(props));
    EventManager.ins.on('push-unique-message', (props) => dialogAction.pushUniqueMessage(props));
    EventManager.ins.on('pop-message', () => dialogAction.pop());
    EventManager.ins.on('clear-message', () => dialogAction.clear());
    
    return () => {
      EventManager.ins.off('internal-server-error');
      EventManager.ins.off('push-message');
      EventManager.ins.off('pop-message');
      EventManager.ins.off('clear-message');
      EventManager.ins.off('push-unique-message');
    }
  })
  return <></>;
}