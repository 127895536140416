import { RoutingPath } from './../../../../routes/routing-path';
import { fullUrl, RequestBaseType, apiAction } from "../../utile";

const path = '/upload';

export type RequestUsersCsvUpload = {
    File: File;
}
const upload = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: (param: RequestBaseType<RequestUsersCsvUpload, {}>) => {
      const formData = new FormData();
      formData.append('Filedata', param.data?.File!);
      formData.append('pass', `/#${RoutingPath.activate}`);
      return apiAction({
        url: full,
        method: 'post',
        ...param,
        data: formData,
    })},
  })
}

export default upload;