import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CheckMessageDialogData, DialogData, DialogsParams, LoadingProps, MessageDialogData } from "../components/dialog/dialog.type";
import { MessageDialog } from '../components/dialog/message-dialog';
import { Spinner } from "../components/ui/spinner/spinner";

export const useDialog = (): DialogsParams => {
  const [dialogStates, setDialogStates] = useState<DialogData[]>([]);
  const currentDialogStates = useRef<DialogData[]>([]);

  const push = useCallback((props: DialogData) => {
    setDialogStates([...cloneDeep(currentDialogStates.current), props]);
    currentDialogStates.current = [...cloneDeep(currentDialogStates.current), props];
  }, []);

  const pop = useCallback(() => {
    currentDialogStates.current.pop();
    setDialogStates(cloneDeep(currentDialogStates.current));
  }, []);

  const clear = useCallback(() => {
    setDialogStates([]);
    currentDialogStates.current = [];
  }, []);

  const pushMessage = useCallback((props: MessageDialogData) => {
    push({
      title: props.title,
      id: props.id,
      element: MessageDialog(props.message, props.buttons),
    });
  }, [push]);

  const pushAsyncCheckMessage = useCallback((props: CheckMessageDialogData) => {
    return new Promise<boolean>((resolve) => {
      push({
        title: props.title,
        element: MessageDialog(props.message, [
          {label: props.buttons.ng.label, callback: () => {
            pop();
            props.buttons.ng.callback?.();
            resolve(false);            
          }, color: 'tertiary'},
          {label: props.buttons.ok.label, callback: () => {
            pop();
            props.buttons.ok.callback?.();
            resolve(true);
          }},
        ]),
      });
    })
  }, [push]);

  const pushUniqueMessage = useCallback((props: MessageDialogData & { id: string }) => {
    const findIndex = currentDialogStates.current.findIndex((v) => v.id === props.id);
    if (!(findIndex > -1)) {
      pushMessage(props);
    } else {
      const origin = cloneDeep(currentDialogStates.current[findIndex]);
      currentDialogStates.current.splice(findIndex, 1);
      setDialogStates([...cloneDeep(currentDialogStates.current), origin]);
      currentDialogStates.current.push(origin);
    };
  }, []);
  
  const pushLoading = useCallback((props?: LoadingProps) => {
    const filter = dialogStates.filter((v) => (v.id?.indexOf('loading') ?? -1) > -1);
    const id = filter.length + 1;
    push({
      title: '',
      id: `loading_${id}`,
      bodyStyle: { paddingBottom: '10px' },
      element:  (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center', gap: '0.5rem', flexDirection: 'column', paddingTop: '50px', paddingBottom: '40px',}}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center', gap: '0.5rem', fontSize: '20px' }}>
            <span>通信中</span>
            <Spinner size="25px" borderSize="4px" />
          </div>
          { props?.customMessage && props.customMessage }
        </div>
      )
    });
  }, [dialogStates, push]);

  return useMemo(() => ({
    push,
    pop,
    clear,
    dialogStates,
    pushMessage,
    pushLoading,
    pushUniqueMessage,
    pushAsyncCheckMessage,
  }), [push, pop, clear, dialogStates, pushMessage, pushLoading])
}