import { fullUrl, RequestBaseType, apiAction } from "../utile";

const path = '/logout';

const logout = (url: string) => () => {
  const full = fullUrl(url, path);
    return({
      post: (param: RequestBaseType<{}, {}>) => apiAction({
        url: full,
        method: 'post',
        ...param,
    }),
  })
}

export default logout;