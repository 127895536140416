import { apiAction, fullUrl, RequestBaseType, ReturnResBase } from '../utile';

const path = '/users/auth';

export type RequestPostAuth = {
  token: string;
}

export type ResponsePostAuth = {
  /** ユーザID pre_users テーブルのuser_idを設定 */
  user_id:	string,
  /** 	メールアドレス */
  email:	string,
  /** 	企業名称 */
  compnay_name:	string,
  /** 	トークン */
  token:	string,
}

const auth = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: (param: RequestBaseType<RequestPostAuth, ReturnResBase<ResponsePostAuth>>) => (apiAction({
      url: full,
      method: 'post',
      ...param,
    }))
  })
}

export default auth;