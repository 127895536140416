import { fullUrl, RequestBaseType, apiAction, ReturnResBase } from "../utile";
import detail from "./detail";
import upload from "./upload";

const path = '/users';

export type RequestGetUsers = {
  /** 並替基準列 1:名前（カナで実施） 2:メールアドレス 3:会社名 4:権限 5:登録日（デフォルト） 6:最終ログイン日 */
  sort_by?: number;
  /** 並替方法 0:昇順（デフォルト） 1:降順 */
  highlow?: number;
  /** 1ページの行数	*/
  limit?: number;
  /** ページ数 */
  page?: number;
  /** 利用停止者を含める */
  is_stoped?: number;
  /** 名前 */
  full_name?: string;
  /** メールアドレス */
  email?: string;
  /** 会社名 */
  company_name?: string;
}

export type RequestPostUsers = {
  /**会社名*/
  company_name: string;
  /**メールアドレス*/
  email: string;
  /**ユーザー権限*/
  role: number;
  /**管理操作権限－ユーザー管理*/
  is_admin_ope1: number;
  /**管理操作権限－マスタ管理*/
  is_admin_ope2: number;
  /**管理操作権限－帳票資料管理*/
  is_admin_ope3: number;
  /**パス*/
  pass: string;
}

export type ResponseGetUsers = {
  /**ユーザーID */
  user_id: string;
  /** メールアドレス */
  email: string;
  /** 企業名 */
  company_name: string;
  /** "名前（usersテーブルをJOINし、family_name と nameを連結した値）" */
  full_name: string;
  /** "名前（カナ）（usersテーブルをJOINし、family_name_kana と name_kanaを連結した値）" */
  full_name_kana: string;
  /** "権限
  1: 管理
  2: 一般
  3:ゲスト" */
  role: number;
  /**"有効フラグ
  0: 無効
  1:有効" */
  is_valid: number;
  /** 作成日時 */
  created_at: string;
  /** "ステータス
  0: 招待中（認証街）
  1:認証済み" */
  status: number;
  /** 最終ログイン日時 */
  login_at: string;
}

export type UserListGetResponseWrap = {
  current_page: number,
  first_page_url: string,
  from: number,
  last_page: number,
  last_page_url: string,
  path: string,
  per_page: number,
  total: number,
  data: ResponseGetUsers[],
}

const users = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    get: (param: RequestBaseType<RequestGetUsers, ReturnResBase<UserListGetResponseWrap>>) => apiAction({
      url: full,
      method: 'get',
      ...param,
    }),
    post: (param: RequestBaseType<RequestPostUsers, {}>) => apiAction({
      url: full,
      method: 'post',
      ...param,
    }),
    upload: upload(full),
    detail: detail(full),
  })
}

export default users;