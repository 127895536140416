import './dialog.scss';
import { useDidMount } from '../../hooks/life-cycle';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../App';
import { useLocation } from 'react-router-dom';

export const Dialog = () => {
  const { dialogAction } = useContext(AppContext);
  const location = useLocation();
  useEffect(() => {
    dialogAction.clear();
  }, [location.pathname]);

  return dialogAction.dialogStates.length ? (
    <>
      {dialogAction.dialogStates.map((v, index) => (
        <div onClick={() => {
          if (v.outClickPop) {
            dialogAction.pop();
          }
        }} key={`dialog-element_${index}`} id={v?.id || ''} className={`dialog_wrap${v.className ? ` ${v.className}` : ''}`}>
          <div className="dialog" style={{...v.style}} onClick={(e) => e.stopPropagation()}>
            {v.title ? (
              <header>{v.title}</header>
            ) : (<></>)}
            < div className="dialog_body" style={v.bodyStyle}>
              <div className="inner">{v.element}</div>
            </div>
          </div>
        </div>
      ))
      }
    </>
  ) : (<></>);
};
