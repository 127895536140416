import { useCallback, useEffect, useRef, useState } from 'react';
import './accordion.scss';

type AccordionProps = {
  summary: string,
  contents: JSX.Element,
  defaultOpen?: boolean,
  toggleOption?: {
    side?: "right" | "left",
    color?: string,
    icon?: JSX.Element,
  }
};

/**
 * 
 * @param props 
 * @returns 
 */
export const Accordion = (props: AccordionProps) => {
  const { summary, contents, defaultOpen = true, toggleOption } = props;
  /* State */
  const contentsWrapEle = useRef<HTMLDivElement>(null);
  const contentsEle = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(defaultOpen);
  // 開閉内容部分の高さ
  const [contentsHeight, setContentsHeight] = useState<number>();
  /* Callback */
  const onEnterSummary = useCallback(() => {
    if (contentsHeight === undefined) {  // 初回のみ
      setContentsHeight(contentsEle.current?.getBoundingClientRect().height || 0);
    }
  }, [contentsHeight]);
  /* Effect */
  useEffect(() => {
    const updateHeight = () => setContentsHeight(contentsEle.current?.getBoundingClientRect().height || 0);
    window.addEventListener('resize', updateHeight);
    return () => { window.removeEventListener('resize', updateHeight); };
  }, []);

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => setOpen((prev) => !prev)} onMouseEnter={onEnterSummary}>
        <span className="accordion-header-summary">{summary}</span>
        <div
          className={`accordion-header-toggle${open ? ' open' : ' close'}${toggleOption?.icon ? '' : ' default_toggle'}`}
          style={{ marginLeft: toggleOption?.side === "right" ? 'auto' : 'initial' }}
        />
      </div>
      <div
        className={`accordion-contents-wrap${open ? ' open' : ''}`}
        ref={contentsWrapEle}
        style={{ height: open ? `calc(${contentsHeight}px)` : 0 }}
      >
        <div className="accordion-contents" ref={contentsEle}>{contents}</div>
      </div>
    </div >
  );
};