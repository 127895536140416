import { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { Button } from '../button/button';
import './drop-zone.scss';

type Props = {
  label: string[] | string,
  title: string,
  validation?: (file: File) => Promise<boolean>,
  callback: (file: File) => void,
  accept: string,
  wrapStyle?: HTMLAttributes<HTMLDivElement>['style'],
  limit?: {
    size: number,
    siPrefix?: 'K' | 'M' | 'G',
  }
}

export const DropZone = (props: Props) => {
  const { label, title, callback, accept, validation, wrapStyle, limit } = props;
  const [isDrag, setIsDrag] = useState(false);
  const viewLabel = useMemo(() => {
    if (label instanceof Array<string>) {
      return label;
    } else {
      return [
        `ここに${label}をドラッグアンドドロップしてください。`,
        'または',
      ]
    }
  }, [label]);

  const onLoadFile = useCallback(async() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.onchange = async(event: any) => {
      const e = event as React.ChangeEvent<HTMLInputElement>;
      const file = e.target.files?.[0];
      if (!file) return;
      if (validation) {
        const valid = await validation(file);
        if (!valid) return;
      }
      callback(file);
    }
    input.click();
    input.remove();
  }, [callback, accept]);

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDrag(true);
  }
  
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDrag(false);
  }

  const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrag(false);
    const files = Array.from(e.dataTransfer.files);
    const file = files[0];
    if (file) {
      callback(file);
    }
  }, [callback]);
  
  return (
    <div
      style={wrapStyle}
      onDrop={(e) => {
        e.preventDefault();
      }
    }>
      <label>{title}</label>
      <div
        className={`drop_zone_container${isDrag ? ' draggable' : ''}`}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        {viewLabel.map((label, i) => (
          <div key={`drop_label_${i}`}>{label}</div>
          ))}
        <Button label="ファイルを選択" style={{ background: '#AE1308' }} onClick={() => onLoadFile()} />
        {!!limit && (
          <div style={{fontSize: '14px', color: '#e53e3e'}}>
            {`アップロード容量：${limit.size}${limit.siPrefix ?? ''}Bまで`}
          </div>
        )}
      </div>
    </div>
  )
}