import { apiAction, fullUrl, RequestBaseType } from "../utile";

const path = '/users/activate';

export type RequestPostActivate = {
  /** トークン */
  token: string;
  /** ユーザーID */
  user_id: string;
  /** メールアドレス */
  email: string;
  /** 姓 */
  family_name: string;
  /** 名 */
  name: string;
  /** 姓（カナ） */
  family_name_kana: string;
  /** 名（カナ） */
  name_kana: string;
  /** パスワード */
  password: string;
}

const activate = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: (param: RequestBaseType<RequestPostActivate, {}>) => (apiAction({
      url: full,
      method: 'post',
      ...param,
    }))
  })
}

export default activate;