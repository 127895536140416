import { Navigate, Route, Routes } from "react-router-dom"
import { Activate } from "../components/pages/activate/activate";
import ErrorToken from "../components/pages/error/error-token";
import { Flyer } from "../components/pages/flyer/flyer";
import { Login } from "../components/pages/login/login"
import { Logo } from "../components/pages/logo/logo";
import { Model } from "../components/pages/model/model";
import PasswordReissue from "../components/pages/login/password-reissue";
import { User } from "../components/pages/user/user";
import { RoutingPath } from './routing-path';
export const RouteConfig = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path={RoutingPath.user} element={<User />} />
      <Route path={RoutingPath.activate} element={<Activate />} />
      <Route path={RoutingPath.tokenError} element={<ErrorToken />} />
      <Route path={RoutingPath.model} element={<Model />} />
      <Route path={RoutingPath.flyer} element={<Flyer />} />
      <Route path={RoutingPath.logo} element={<Logo />} />
      {/* <Route path={RoutingPath.passwordReissue} element={<PasswordReissue />} /> */}
      <Route path="*" element={<Navigate to={RoutingPath.login} />} />
    </Routes>
  )
}