import './spinner.scss';

type Props = {
  size: string;
  borderSize?: string;
}

export const Spinner = (props: Props) => {
  const { size, borderSize = '12px' } = props;
  return (
    <div className="loader-container" style={{width: size, height: size}}>
      <div
        className="loader"
        style={{
          border: `${borderSize} solid rgb(162, 161, 161)`,
          borderTop: `${borderSize} solid #e35d67`,     
        }}
      />
    </div>
  )
}