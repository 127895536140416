import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { RoutingPath } from '../../../routes/routing-path';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDidMount, useOnesDidMount } from '../../../hooks/life-cycle';
import { QueryParamFormatter } from '../../../lib/query-param-formatter';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../api';
import { ResponsePostAuth } from '../../../api/routes/auth';
import { useContext } from 'react';
import { AppContext } from '../../../App';
import { ActivateCheck, ActivateEditData } from './activate-check';
import { useValidation } from '../../../hooks/use-validation';
import { ReplaceEisu } from '../../../lib/replace-eisu';
import { ConfirmValidation } from '../../../lib/validation/validation-factory';

const confirmValidWrap = (origin: string, confirm: string) => ConfirmValidation(origin, confirm, ['パスワードが一致しません']);

export const Activate = () => {
  const navigate = useNavigate();
  const { validAll } = useValidation();
  const { dialogAction } = useContext(AppContext)
// -------------------- state --------------------
  const [familyName, setFamilyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const [resData, setResData] = useState<ResponsePostAuth | null>(null);
  const [mode, setMode] = useState<'edit' | 'check'>('edit');

  const validation = useMemo(() => {
    return {
      firstName: validAll(firstName, 'require', 'length30'),
      firstNameKana: validAll(firstNameKana, 'require', 'length30', 'kana'),
      familyName: validAll(familyName, 'require', 'length30'),
      familyNameKana: validAll(familyNameKana, 'require', 'length30', 'kana'),
      password: validAll(password, 'require', 'lengthOver8'),
      passwordConfirm: [validAll(passwordConfirm, 'require', 'lengthOver8'), confirmValidWrap(password, passwordConfirm)].flat(),
    }
  }, [firstName, firstNameKana, familyName, familyNameKana, password, passwordConfirm]);

  const isDisabled = useMemo(() => {
    return !Object.values(validation).every((v) => !v.length);
  }, [validation]);

  const editData: ActivateEditData = useMemo(() => {
    return {
      name: firstName,
      familyName: familyName,
      nameKana: firstNameKana,
      familyNameKana: familyNameKana,
    }
  }, [firstName, firstNameKana, familyName, familyNameKana]);

  useOnesDidMount(() => {
    const token = QueryParamFormatter.queryParse().token;
    if (!token) {
      navigate(RoutingPath.login);
      return;
    }
    Api.connect().auth().post({
      data: {
        token: token,
      },
      onSuccess: (res) => {
        setResData(res.data.body.data)
        setErrorFlag(true);
      },
      onError: (e) => {
        navigate(RoutingPath.tokenError)
      }
    });
  });

  const onSubmit = useCallback(() => new Promise<void>((resolve) => {
    if (!resData || isDisabled) {
      resolve();
      return;
    }
    Api.connect().activate().post({
      data: {
        email: resData.email,
        family_name: familyName,
        family_name_kana: familyNameKana,
        name: firstName,
        name_kana: firstNameKana,
        password,
        token: resData.token,
        user_id: resData.user_id,
      },
      onSuccess: () => {
        dialogAction.pushMessage({
          title: '確認',
          message: [
            '登録が完了しました。',
          ],
          buttons: [
            { 
              label: 'OK',
              callback: () => {
                dialogAction.clear();
                navigate(RoutingPath.login)
              },
            },
          ]
        });
      },
      onFinally: () => {
        resolve();
        return;
      }
    })
  }), [isDisabled, resData, firstName, firstNameKana, familyName, familyNameKana, password]);

  const onCheck = useCallback(() => {
    if (!resData || isDisabled) return;
    setMode('check');
  }, [resData, isDisabled]);

  return (
    !errorFlag ?<></> :  mode === 'edit' ? (
      <div className="dialog_wrap direct_access add">
        <div className="dialog">
          <header>
            <div>MAPS&nbsp;PLUSに招待されました。</div>
            <div>以下情報を入力し、会員登録を完了させてください。</div>
          </header>

          <div className="dialog_body">
            <div className="edit_wrap">
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">ユーザー名<span className="required">必須</span></div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">姓</label>
                      <Input
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                        validations={validation.familyName}
                      />
                    </div>
                    <div>
                      <label className="fix_size">名</label>
                      <Input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        validations={validation.firstName}
                      />
                    </div>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">ユーザー名（カナ）<span className="required">必須</span></div>
                  <div className="item_cnt flex">
                    <div style={{ marginRight: '30px' }}>
                      <label className="fix_size">セイ</label>
                      <Input
                        value={familyNameKana}
                        onChange={(e) => setFamilyNameKana(e.target.value)}
                        validations={validation.familyNameKana}
                      />
                    </div>
                    <div>
                      <label className="fix_size">メイ</label>
                      <Input
                        value={firstNameKana}
                        onChange={(e) => setFirstNameKana(e.target.value)}
                        validations={validation.firstNameKana}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">メールアドレス</div>
                  <div className="item_cnt">
                    <label className="text_box">{resData?.email ?? ''}</label>
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">パスワード<span className="required">必須</span></div>
                  <div className="item_cnt">
                    <Input
                      type={'password'}
                      value={password}
                      onChange={(e) => setPassword(ReplaceEisu(e.target.value))}
                      validations={validation.password}
                    />
                    <span className="comment">※半角英数８文字以上</span>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">パスワード（確認）<span className="required">必須</span></div>
                  <div className="item_cnt">
                    <Input
                      type={'password'}
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(ReplaceEisu(e.target.value))}
                      validations={validation.passwordConfirm}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer>
              <Button
                size="large"
                color="primary"
                label={'入力確認'}
                onClick={() => onCheck()}
                disabled={isDisabled}
              />
            </footer>
          </div>
        </div>
      </div>
    ) : <ActivateCheck onBack={() => setMode('edit')} onSubmit={async() => {
      onSubmit();
    }} data={editData} />
  );
};
