import EventEmitter from 'eventemitter3';
import axios, { AxiosInstance, AxiosResponse, CancelTokenStatic } from 'axios';
import { QueueManager } from './queue-manager';
import { QueueItem } from './queue-item';
import { LogDecorator } from '@tenryu/log-decorator';
import {
  ConnectionEvent,
  ConnectionOption,
  ConnectionRequest,
  CreateQueryParams,
  DEFAULT_CONNECTION_OPTION,
  IConnection,
} from './connection-base';

class ConnectionForClientClass extends EventEmitter<ConnectionEvent> implements IConnection {

  private readonly axios: AxiosInstance;
  private queueManager;

  constructor() {
    super();
    this.queueManager = new QueueManager();
    this.queueManager.start();
    this.axios = axios.create({
      xsrfHeaderName: 'XSRF-TOKEN',
      withCredentials: true,
    });
    // this.axios.defaults.headers.post['Accept'] = '*/*';
    // this.axios.defaults.withCredentials = true;
  }

  get<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption) {
    const opt = option ?? DEFAULT_CONNECTION_OPTION;
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      const queryString = request.data ? (option?.directParam) ? '' : CreateQueryParams(request.data) : '';
      const url = queryString ? `${request.url}?${queryString}` : request.url;
      const params: {[key in string]: any} = option?.directParam ? (request.data ?? {}) : {};
      const queueItem = new QueueItem({
        description: `[GET] ${url}`,
        func: () => this.axios
          .get<TResponse>(url, {
            headers: request.headers,
            params,
            responseType: option?.isBlob ? 'blob' : undefined,
            cancelToken: option?.cancelable?.token,
          })
          .then((v) => this.log('GET', url, request.data, v)),
        onSuccess: (v) => {
          resolve(v);
        },
        onError: (e) => {
          reject(e);
        },
        priority: opt.priority,
      });
      this.queueManager.enqueue(queueItem);
    });
  }

  post<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption) {
    const opt = option ?? DEFAULT_CONNECTION_OPTION;
    let formData = new FormData();
    if (request.data instanceof FormData) {
      formData = request.data;
    } else {
      const keys = (Object.keys(request.data ?? {}) ?? []);
      if (request.data && keys.length) {
        keys.forEach((key) => {
          if (request.data) {
            const _key = key as keyof typeof request.data;
            const value = request.data[_key];
            if (value !== undefined) {
              if (value instanceof Blob) {
                formData.append(String(_key), value);
              } else {
                formData.append(String(_key), value as string);
              }
            }
          }
        })
      }
    }
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      const url = request.url;
      const queueItem = new QueueItem({
        description: `[POST] ${url}`,
        func: () => this.axios
          .post<TResponse>(url, formData, { headers: {...request.headers, }, withCredentials: true })
          .then((v) => this.log('POST', url, request.data, v)),
        onSuccess: (v) => {
          resolve(v);
        },
        onError: (e) => {
          reject(e);
        },
        priority: opt.priority,
      });
      this.queueManager.enqueue(queueItem);
    });
  }

  put<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption) {
    const opt = option ?? DEFAULT_CONNECTION_OPTION;
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      const url = request.url;
      const queueItem = new QueueItem({
        description: `[PUT] ${url}`,
        func: () => this.axios
          .put<TResponse>(url, request.data, { headers: request.headers })
          .then((v) => this.log('PUT', url, request.data, v)),
        onSuccess: (v) => {
          resolve(v);
        },
        onError: (e) => {
          reject(e);
        },
        priority: opt.priority,
      });
      this.queueManager.enqueue(queueItem);
    });
  }

  delete<TRequest, TResponse>(request: ConnectionRequest<TRequest>, option?: ConnectionOption) {
    const opt = option ?? DEFAULT_CONNECTION_OPTION;
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      const queryString = request.data ? CreateQueryParams(request.data) : '';
      const url = queryString ? `${request.url}?${queryString}` : request.url;
      const queueItem = new QueueItem({
        description: `[DELETE] ${url}`,
        func: () => this.axios
          .delete<TResponse>(url, { headers: request.headers })
          .then((v) => this.log('DELETE', url, request.data, v)),
        onSuccess: (v) => {
          resolve(v);
        },
        onError: (e) => {
          reject(e);
        },
        priority: opt.priority,
      });
      this.queueManager.enqueue(queueItem);
    });
  }

  stop() {
    // TODO : 全てのリクエストをストップさせる処理を実装
  }

  count(): number {
    return 0;
  }

  private log(method: string, url: string, param: any, response: AxiosResponse<any>) {
    const _url = new URL(url);
    const methodColor = response.status >= 200 && response.status < 300 ? 'green' : 'red';
    console.groupCollapsed(...LogDecorator(`[<${methodColor}>${method}</${methodColor}>] ${_url.pathname}`));
    console.log('url      : ', _url.href);
    console.log('param    : ', param);
    console.log('response : ', response);
    console.groupEnd();
    return response;
  }

}

export const ConnectionForClient = new ConnectionForClientClass();
