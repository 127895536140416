import { fullUrl, RequestBaseType, apiAction, ReturnResBase, RequestGetListBase } from "../utile";
import detail from "./detail";

const path = '/flyers';

export type RequestPostFlyers = {
    /** チラシファイル */
    File: File;
}


export type ResponseGetFlyers = {
    /** チラシID */
    id: string;
    /** ファイル名 */
    filename: string;
    /** バージョン値 */
    version: string;
    /** 作成日時 */
    created_at: string;
    /** 最終更新日時 */
    updated_at: string;
}

const flyers = (url: string) => () => {
    const full = fullUrl(url, path);
    return ({
        get: (param: RequestBaseType<RequestGetListBase, ReturnResBase<ResponseGetFlyers[]>>) => apiAction({
            url: full,
            method: 'get',
            ...param,
        }),
        post: (param: RequestBaseType<RequestPostFlyers, {}>) => {
          const formData = new FormData();
          formData.append('Filedata' , param.data?.File!);
          return apiAction({
            url: `${full}/upload`,
            method: 'post',
            ...param,
            data: formData,
          });
        },
        detail: detail(full),
    })
}

export default flyers;