import { fullUrl, RequestBaseType, apiAction, ReturnResBase } from "../../../utile";

const path = '/reinvitation';
export type RequestPostReinvitation = {
    /** パス */
    pass: string;
}
const reinvitation = (url: string) => () => {
  const full = fullUrl(url, path);
  return ({
    post: (param: RequestBaseType<RequestPostReinvitation, {}>) => apiAction({
      url: full,
      method: 'post',
      ...param,
    }),
  })
}
export default reinvitation;