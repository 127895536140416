import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import '../../../App.scss';
import './login.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useValidation } from '../../../hooks/use-validation';
import { RoutingPath } from '../../../routes/routing-path';
import { ReplaceEisu } from '../../../lib/replace-eisu';
import API, { Api } from '../../../api';
import { useContext } from 'react';
import { AppContext } from '../../../App';

export const Login = () => {

  const navigate = useNavigate();
  const { valid } = useValidation();
  const { setUser } = useContext(AppContext);
  const [id, setId] = useState('');
  const idValid = useMemo(() => valid(id, 'require'), [id]);
  const [password, setPassword] = useState('');
  const passValid = useMemo(() => valid(password, 'require'), [password]);
  const [loadingApi, setLoadingApi] = useState(false);
  const isDisabled = useMemo(() => {
    return (![idValid, passValid].every((v) => !v.length)) || loadingApi;
  }, [idValid, passValid, loadingApi]);

  const handleChangeId = useCallback((value: string) => {
   setId(ReplaceEisu(value));
  }, []);

  const handleChangePassword = useCallback((value: string) => {
    setPassword(ReplaceEisu(value));
  }, []);

  const login = useCallback(() => {
    setLoadingApi(true);
    Api.connect().login().post({
      onSuccess: (res) => {
        if (res.data.body.data?.user_id) {
        }
        setUser(res.data.body.data);
        API.isLogin = true;
        navigate(RoutingPath.user);
      },
      onFinally: () => setLoadingApi(false),
      data: {
        email: id,
        password,
      }
    });
  }, [id, password]);

  const handleClickSubmit = useCallback(() => {
    if (isDisabled) return;
    login();
  }, [id, passValid, isDisabled, login]);

  useEffect(() => {
    API.isLogin = false;
    setUser(null);
    Api.connect().logout().post({
      ignoreErrorDialog: true,
    });
  }, []);

  return (
    <>
      <div
        id="login"
        className="main_cnt company"
      >
        <div className="wrapper">
          <h1>
            MAPS&nbsp;PLUS
          </h1>
          <h2>
            管理サイト
          </h2>
          {/*<p>ご利用の方はログインして下さい</p>*/}
          <div className="input_wrap">
            <Input
              value={id}
              onChange={(e) => handleChangeId(e.target.value)}
              placeholder="ユーザーID"
              validations={idValid}
              disabled={loadingApi}
            />
          </div>
          <div className="input_wrap">
            <Input
              type="password"
              value={password}
              onChange={(e) => handleChangePassword(e.target.value)}
              onKeyPress={(e) => {
                if (isDisabled) return;
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                  handleClickSubmit();
                }
              }}
              placeholder="パスワード"
              validations={passValid}
              disabled={loadingApi}
            />
          </div>
          <Button
              label="ログイン"
              className="login_button"
              onClick={handleClickSubmit}
              disabled={isDisabled}
            />
            <br />
            {/* <Link
              style={{ textDecoration: 'none', color: '#0000EE', fontSize: '16px' }}
              to={RoutingPath.passwordReissue}
            >パスワードを忘れた方はコチラ</Link> */}
        </div>
      </div>
    </>
  );
};
