import { SideBar } from "../../ui/sidebar/sidebar"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../../../App';
import { SortListType, Table } from "../../ui/table/table";
import { Button } from "../../ui/button/button";
import { Pager } from "../../ui/pager/pager";
import { Input } from "../../ui/input/input";
import { UserEditDialog } from './user-edit.dialog';
import { RequestGetUsers, ResponseGetUsers } from '../../../api/routes/users/index';
import { DateFormatter } from "../../../lib/date-formatter";
import { Api } from "../../../api";
import { SearchBox } from "./search-box";
import { RoutingPath } from "../../../routes/routing-path";
import Encoding from 'encoding-japanese';
import './user.scss'
import { SendMailDialog } from "../../dialog/send-mail-dialog";
import { DropZone } from "../../ui/drop-zone/drop-zone";
import { UserDropZone } from "../../ui/drop-zone/user-drop-zone";

type ReinvitationButtonProps = {
  id: string,
}

const ReinvitationButton = (props: ReinvitationButtonProps) => {
  const { id } = props;

  /* hook */
  const { dialogAction } = useContext(AppContext);

  /* state */
  const [load, setLoad] = useState(false);

  /* ユーザー再招待処理 */
  const handleClickReinvitation = useCallback((id: string) => {
    setLoad(true);
    Api.connect().users().detail(id).reinvitation().post({
      data: {
        pass: '#/activate',
      },
      onSuccess: () => {
        dialogAction.pushMessage({
          title: 'ユーザー再招待',
          message: [
            'ユーザー再招待が完了しました。',
          ],
          buttons: [
            { label: 'OK', callback: () => dialogAction.pop() },
          ]
        });
      },
      onError: (e, d) => {
        if (d) return;
        dialogAction.pushMessage({
          title: '確認',
          message: [
            'ユーザー再招待に失敗しました。',
            '再度招待をお試しください。'
          ],
          buttons: [
            { label: 'OK', callback: () => dialogAction.pop() },
          ]
        });        
      },
      onFinally: () => setLoad(false),
    })
  }, [dialogAction]);
  
  return (
    <Button onClick={() => handleClickReinvitation(id)} label="再招待" style={{ minWidth: '44px' }} disabled={load} />
  )
}

const SORT_INDEX_LIST = [0, 1, 2, 3, 4, 5];

export const User = () => {
  /* hook */
  const { dialogAction, setUser, user } = useContext(AppContext);

  /* state */
  const [list, setList] = useState<ResponseGetUsers[]>([]);
  const [totalCount, setTotalCount] = useState('-');
  const [lastPage, setLastPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const offsetRef = useRef<null | number>(1);
  /* 検索state */
  const [searchParam, setSearchParam] = useState<RequestGetUsers>({ sort_by: 5 });

  /* callback */
  const viewRole = useCallback((value: number) => {
    switch (value) {
      case 1:
        return '管理';
      case 2:
        return '一般';
      case 3:
        return 'ゲスト';
      default:
        return 'ゲスト';
    }
  }, []);

  const getMe = useCallback(() => {
    Api.connect().users().detail(user?.user_id!).get({
      onSuccess: (res) => {
        setUser({ ...res.data.body.data });
      }
    });
  }, [user]);

  /* 新規登録 編集 */
  const handleClickEdit = useCallback((id?: string) => {
    dialogAction.push({
      title: !!id ? 'ユーザー編集': 'ユーザー 新規登録',
      style: {
        width: '80%',
        maxWidth: '60em',
      },
      element: <UserEditDialog id={id} callback={() => {
        getList({}, false);
        getMe();
        
      }} />
    })
  }, [getMe]);

  /* ユーザー再招待処理 */
  const handleClickReinvitation = useCallback((id: string) => {
    Api.connect().users().detail(id).reinvitation().post({
      data: {
        pass: `/#${RoutingPath.activate}`,
      },
      onSuccess: () => {
        dialogAction.pushMessage({
          title: 'ユーザー再招待',
          message: [
            'ユーザー再招待が完了しました。',
          ],
          buttons: [
            { label: 'OK', callback: () => dialogAction.pop() },
          ]
        });
      },
      onError: (e, d) => {
        if (d) return;
        dialogAction.pushMessage({
          title: '確認',
          message: [
            'ユーザー再招待に失敗しました。',
            '再度招待をお試しください。'
          ],
          buttons: [
            { label: 'OK', callback: () => dialogAction.pop() },
          ]
        });        
      }
    })
  }, [dialogAction]);

  /*
   * 一覧取得処理
   * param => 入れた値で現状の検索stateを上書き 未定義は前回分引き継ぎ
   * _initPage => pageの値を初期化
   */
  const getList = useCallback((param: RequestGetUsers, _initPage: boolean = true) => {
    const searchData = { ...param };
    if (_initPage) searchData.page = 0;
    const request = {
      ...searchParam,
      ...searchData,
    };
    setSearchParam(request);
    Api.connect().users().get({
      data: {
        ...request,
        page: ((request.page ?? 0) + 1),
      },
      onSuccess: (res) => {
        const _data = res.data.body.data;
        if (_data.total < 1) {
          dialogAction.pushUniqueMessage({
            id: 'search_user',
            title: '確認',
            message: ['検索結果が見つかりませんでした。'],
            buttons: [
              { label: 'OK', callback: () => dialogAction.pop()},
            ]
          });
        } else {
          if (_data.data.length === 0) {
            getList({
              ...request,
              page: _data.last_page - 1,
            }, false)
          } else {
            setList(_data.data);
            setTotalCount(_data.total?.toLocaleString() ?? '-');
            let lp = (res.data?.body?.data?.last_page ?? 1) - 1;
            if (lp < 0) lp = 0;
            setLastPage(lp);
          }
        }
      }
    })
  }, [offset, dialogAction, searchParam]);

  /* CSVアップロード導線 */
  const handleClickCsvDownload = useCallback(async () => {
    dialogAction.push({
      title: '',
      element: <UserDropZone getList={getList} />,
      outClickPop: true,
      className: "csv_upload_dialog",
    })
  }, [getList])
    
  const handleClickCheckDisableUser = useCallback((isStoped: boolean) => {
    getList({ is_stoped: isStoped ? 1 : 0 });
  }, [getList, dialogAction]);

  const onChangePage = useCallback((page: number) => {
    getList({ page }, false);
  }, [getList]);

  const onTableSort = useCallback((_sort_by: number, _highlow: number) => {
    const { sort_by = 1 } = searchParam;
    const hl = sort_by === _sort_by ? _highlow : 0;
    getList({ sort_by: _sort_by, highlow: hl });
  }, [getList, searchParam]);

  /* memo */
  const tableBody = useMemo(() => {
    return list.map((data) => ({
      data: [
        data.full_name,
        data.email,
        data.company_name,
        viewRole(data.role),
        DateFormatter.str2str(data.created_at) ?? '',
        DateFormatter.str2str(data.login_at) ?? '',
        data.status ? (
          <Button
            onClick={() => handleClickEdit(data.user_id)}
            label="変更"
            style={{ minWidth: '44px' }}
          />
        ) : (
          <ReinvitationButton id={data.user_id} />
        ),
      ],
      isGray: !data.is_valid,
    }))
  }, [list, handleClickEdit, handleClickReinvitation, viewRole]);

  const sortList: SortListType[] = useMemo(() => {
    const select = (searchParam.sort_by ?? 1);
    const isSelect = (index: number) => ((index + 1) === select ? 'active' : '');
    return SORT_INDEX_LIST.map((v) => {
      return {
        index: v,
        key: v + 1,
        type: (isSelect(v) && searchParam.highlow) ? 'up' : 'down',
        active: isSelect(v),
        callback: () => {},
      }
    })
  }, [searchParam]);

  /* effect */
  useEffect(() => {
    getList({});
  }, []);

  return (
    <div id="App">
      <SideBar />
      <section className="user_list_container">
        <header className="header_wrap">
          <div className="title_wrap">
            <h2 className="title">ユーザー一覧</h2>
            <div className="list_count">件数&nbsp;<span style={{fontSize: '16px'}}>{totalCount}</span>件</div>
          </div>
          <div className="menu_wrap">
            <div style={{ cursor: 'pointer', marginRight: '20px' }} onClick={() => handleClickCheckDisableUser(!searchParam.is_stoped)}>
              <Input type="checkbox" checked={!!searchParam.is_stoped} style={{width: 'max-content', marginRight: '4px', cursor: 'pointer'}} />
              利用停止ユーザーを表示する
            </div>

            <Button
              label="ユーザー新規登録"
              className="login_button"
              onClick={() => handleClickEdit()}
            />
            <Button
              label="CSVアップロード"
              className="login_button"
              onClick={() => handleClickCsvDownload()}
            />
          </div>
        </header>
        <SearchBox getList={(req) => getList(req)} />
        <div className="table_wrap">
          {/* <div className="table_container"> */}
            <Table
              head={['名前', 'メールアドレス', '会社名', '権限', '登録日', '最終作業日', '']}
              body={tableBody}
              alignList={['c', 'c', 'c', 'c', 'c', 'c', 'c']}
              alignBodyList={['l', 'l', 'l', 'c', 'c', 'c', 'c']}
              sortList={sortList}
              setSortBy={() => {}}
              setHighlow={() => {}}
              setSort={onTableSort}
            />
          {/* </div> */}
        </div>
          <Pager totalPage={lastPage} currentPage={searchParam.page ?? 0} callback={onChangePage} onChange={() => {}} />
      </section>
    </div>
  )
}