import { fullUrl, RequestBaseType, apiAction, ReturnResBase } from "../../utile";
import reinvitation from "./reinvitation";

export type RequestPostUserDetail = {
    /** 会社名 */
    company_name: string;
    /** 姓 */
    family_name: string;
    /** 名 */
    name: string;
    /** セイ */
    family_name_kana: string;
    /** メイ */
    name_kana: string;
    /** ユーザー権限 */
    role: number;
    /** 管理操作権限－ユーザー管理 */
    is_admin_ope1: number;
    /** 管理操作権限－マスタ管理 */
    is_admin_ope2: number;
    /** 管理操作権限－帳票資料管理 */
    is_admin_ope3: number;
    /** 利用停止 */
    is_stoped: number;
    /** 新パスワード */
    password?: string;
}

export type ResponseGetUserDetail = {
    user_id: string;
    email: string;
    company_name: string;
    family_name: string;
    name: string;
    family_name_kana: string;
    name_kana: string;
    role: number;
    is_admin_ope1: number;
    is_admin_ope2: number;
    is_admin_ope3: number;
    created_at: string;
    status: number;
    is_valid: number;
    login_at: string;
    deleted_at: string;
}

const detail = (url: string) => (id: string) => {
  const full = fullUrl(url, `/${id}`);
  return ({
    get: (param: RequestBaseType<{}, ReturnResBase<ResponseGetUserDetail>>) => apiAction({
      url: full,
      method: 'get',
      ...param,
    }),
    post: (param: RequestBaseType<RequestPostUserDetail, {}>) => apiAction({
      url: full,
      method: 'post',
      ...param,
    }),
    reinvitation: reinvitation(full),
  })
}
export default detail;