export const FileSizeFormatter = (size: number, siPrefix?: 'K' | 'M' | 'G') => {
  if (!siPrefix) return size;
  const coefficient = 1024;
  switch (siPrefix) {
    case 'K' : 
      return size / coefficient;
    case 'M' :
      return size / coefficient / coefficient;
    case 'G' :
      return size / coefficient / coefficient / coefficient;
    default :
      return size;
  }
}