import EventEmitter from 'eventemitter3';
import { MessageDialogData } from '../components/dialog/dialog.type';

export type EventType = {
  'internal-server-error': () => void,
  'push-message': (props: MessageDialogData) => void,
  'push-unique-message': (props: MessageDialogData & { id: string }) => void,
  'pop-message': () => void,
  'clear-message': () => void,
}

export class EventManager extends EventEmitter<EventType> {

  private static _ins: EventManager;
  private initialized: boolean = false;

  private constructor() {
    super();
  }

  static get ins() {
    if (EventManager._ins) {
      return EventManager._ins;
    }
    return EventManager._ins = new EventManager();
  }

  initialize() {
    if (this.initialized) {
      // console.error('すでに初期化済みです !!');
      return;
    }
    this.initialized = true;
  }

  destroy(): void {
  }

  addListener(
    // event: keyof EventType | EventEmitter.EventNames<string | symbol>,
    event: keyof EventType,
    fn: EventEmitter.EventListener<EventType, any>,
    context?: any,
  ) {
    return super.addListener(event, fn, context);
  }

  removeListener(
    event: keyof EventType,
    // fn?: EventEmitter.EventListener<EventType, keyof EventType>,
    fn?: EventEmitter.EventListener<EventType, any>,
    context?: any,
    once?: boolean,
  ): this {
    return super.removeListener(event, fn, context, once);
  }
}

EventManager.ins.initialize();
