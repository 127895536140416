import { useState, useContext, useCallback } from 'react';
import { Button } from "../button/button"
import './user-drop-zone.scss'
import { AppContext } from '../../../App';
import { Api } from '../../../api';
import Encoding from 'encoding-japanese';
import { RequestGetUsers } from '../../../api/routes/users';
import { FileInfoCollection } from '../../../collection/file-info-collection';
import { FileSizeFormatter } from '../../../lib/file-size-formatter';

type Props = {
  getList: (param: RequestGetUsers, _initPage?: boolean) => void,
}

export const UserDropZone = (props: Props) => {
  const { getList } = props;
  const { dialogAction } = useContext(AppContext);
  const [draggable, setDraggable] = useState(false);

  const onDropFile = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggable(false);
    const files = e.dataTransfer.files;
    const file = files[0];
    if (!file) return;
    uploadCsv(file);
  }, []);

  const onClickUploadButton = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'text/csv';
    input.onchange = async (e: any) => {
      const event = e as React.ChangeEvent<HTMLInputElement>;
      const file = event.target.files?.[0];
      if (!file) return;
      uploadCsv(file);
    }
    input.click();
    input.remove();
  }, []);

  const uploadCsv = useCallback(async(file: File) => {
    const getFile = () => new Promise<File>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const array = Encoding.stringToCode(result);
        const detected = Encoding.detect(array);
        if (detected !== 'UTF8') {
          const convertArray = Encoding.convert(array, 'UTF8');
          const unit8Array = new Uint8Array(convertArray);
          const convertFile = new File([unit8Array], 'template_sjis.csv', {
            type: file.type,
          });
          resolve(convertFile);
        } else {
          resolve(file);
        }
      }
      reader.readAsBinaryString(file);  
    });

    const isCsv = file.type === 'text/csv';
    const pushMessage = () => {
      dialogAction.clear();
      dialogAction.pushMessage({
        title: isCsv ? 'CSVアップロード': '確認',
        message: isCsv ? [
          `CSVのアップロードが完了しました。`
        ] : [
          '選択したファイル形式が異なります。',
          `CSVファイルを選択してください。`
        ],
        buttons: [
          { label: 'OK', callback: () => {
            if (isCsv) {
              getList({}, false);
              dialogAction.clear();
            } else {
              dialogAction.pop();
            }
          }}
        ],
      });
    }
    if (isCsv) {
      const sendFile = await getFile();
      const limit = FileInfoCollection.LIMIT.userCsv;
      if (FileSizeFormatter(file.size, 'M') > limit) {
        dialogAction.pushMessage({
          title: '確認',
          message: [
            <>
              <span>指定されたファイルが</span>
              <span style={{ color: '#e53e3e' }}>{`${limit}MB`}</span>
              <span>を超過しています。</span>
            </>
          ],
          buttons: [
            { label: 'OK', callback: () => dialogAction.pop() },
          ]
        })
        return;
      }

      dialogAction.pushLoading();
      Api.connect().users().upload().post({
        data: {
          File: sendFile,
        },
        onSuccess: () => {
          pushMessage();
        },
        popLoading: true,
      });
    } else {
      pushMessage();
    }
  }, [getList]);

  return (
    <div
      onDrop={onDropFile}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggable(true)}
      }
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggable(false);
      }}
      className={`csv_upload_dialog__body${draggable ? ' draggable' : '' }`}
    >
      <div
        onMouseEnter={e => e.stopPropagation()}
        onDragLeave={e => e.stopPropagation()}
      >csvファイルをドラッグ＆ドロップしてください。
      </div>
      <div
        onMouseEnter={e => e.stopPropagation()}
        onDragLeave={e => e.stopPropagation()}
      >またはファイルを選択してください
      </div>
      <div>
        <Button label="ファイルを選択" onClick={() => onClickUploadButton()} onDragEnter={e => e.stopPropagation()} onDragLeave={e => e.stopPropagation()} />
        <div style={{textAlign: 'center', fontSize: '14px', color: '#e53e3e'}}>
          アップロード容量：{FileInfoCollection.LIMIT.userCsv}MBまで
        </div>
      </div>
    </div>
  )
}