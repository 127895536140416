import { useCallback, useState, useContext, useEffect } from 'react';
import { Api } from "../../../api"
import { ResponseModels } from "../../../api/routes/models"
import { useOnesDidMount } from "../../../hooks/life-cycle"
import { DateFormatter } from "../../../lib/date-formatter"
import { Button } from "../../ui/button/button"
import { DropZone } from "../../ui/drop-zone/drop-zone"
import { SideBar } from "../../ui/sidebar/sidebar"
import './model.scss'
import { AppContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { RoutingPath } from '../../../routes/routing-path';
import { FileSizeFormatter } from '../../../lib/file-size-formatter';
import { FileInfoCollection } from '../../../collection/file-info-collection';

export const Model = () => {
  const { dialogAction } = useContext(AppContext);
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [info, setInfo] = useState<ResponseModels | null | undefined>(undefined);
  const [currentFile, setCurrentFile] = useState<File | null>(null);

  const getInfo = useCallback(() => {
    Api.connect().models().get({
      onSuccess: (res) => {
        setInfo(res.data.body.data);
      }
    });
  }, []);

  const onLoadFile = useCallback((file: File) => {
    const acceptTypes = ['application/zip', 'application/x-zip-compressed'];
    if (!acceptTypes.includes(file.type)) {
      dialogAction.pushMessage({
        title: '機種情報アップロード',
        message: [
          '選択したファイル形式が異なります。',
          'アップロードするファイルはzip形式で選択してください。',
        ],
        buttons: [
          { label: 'OK', callback: () => dialogAction.pop() }
        ]
      });
      return;
    } else {
      const limit = FileInfoCollection.LIMIT.model;
      console.log(FileSizeFormatter(file.size, 'M'));
      if (FileSizeFormatter(file.size, 'M') > limit) {
        dialogAction.pushMessage({
          title: '確認',
          message: [
            <>
              <span>指定されたファイルが</span>
              <span style={{ color: '#e53e3e' }}>{`${limit}MB`}</span>
              <span>を超過しています。</span>
            </>
          ],
          buttons: [
            { label: 'OK', callback: () => dialogAction.pop() },
          ]
        })
        return;
      }
  
      setCurrentFile(file);
    }
  }, []);

  const onClickCancel = useCallback(() => setCurrentFile(null), []);

  const onClickUpload = useCallback(async() => {
    if (!currentFile) return;
    const pushCheck = () => new Promise<boolean>((resolve) => {
      dialogAction.pushMessage({
        title: `機種情報アップロード`,
        message: [
          `${currentFile.name}をアップロードします。`,
          `よろしいですか？`,
          (
            <div style={{ color: '#e53e3e', marginTop: '1rem' }}>
              ※ この処理は5分以上かかる場合があります
            </div>
          ),
        ],
        buttons: [
          { label: 'キャンセル', callback: () => resolve(false), color: 'tertiary' },
          { label: '登録', callback: () => resolve(true) },
        ],
      })
    });
    const check = await pushCheck();
    dialogAction.pop();
    if (!check) return;
    dialogAction.pushLoading({
      customMessage: (
        <div style={{ color: '#e53e3e', marginTop: '1rem' }}>
          ※ この処理は5分以上かかる場合があります
        </div>
      )
    });
    Api.connect().models().post({
      data: {
        File: currentFile,
      },
      popLoading: true,
      onSuccess: () => {
        dialogAction.pushMessage({
          title: '機種情報アップロード',
          message: [
            '登録が完了しました。',
          ],
          buttons: [
            { label: 'OK', 
              callback: () => {
                dialogAction.pop();
                getInfo();
              }
            },
          ],
        });
        setCurrentFile(null);
      }
    })
  }, [currentFile])

  useOnesDidMount(() => {
    getInfo();
  });

  useEffect(() => {
    if (user && !user.is_admin_ope2) {
      navigate(RoutingPath.user);
    }
  }, [user]);

  return (
    <div id="App">
      <SideBar />
      <section className="model_container">
        <header className="header_wrap">
          <div className="title_wrap">
            <h2 className="title">機種マスタ入替</h2>
          </div>
        </header>
        <div className="model_body_container">
          <div className="model_body_main_cnt">
            <DropZone
              label="MASISの機種情報"
              title="MASISの機種情報をアップロードします。"
              accept=".zip"
              callback={onLoadFile}
              limit={{
                size: FileInfoCollection.LIMIT.model,
                siPrefix: 'M',
              }}
            />
            <div className="file_name_container">
              選択したファイル：{currentFile?.name ?? ''}
            </div>
            <div className="button_area">
              <Button
                size="large"
                color="tertiary"
                label="キャンセル"
                disabled={!currentFile}
                onClick={() => onClickCancel()}
              />
              <Button
                size="large"
                label="アップロード"
                disabled={!currentFile}
                onClick={() => onClickUpload()}
              />
            </div>
            <div className="model_update_date">
              { info === undefined ? (<></>) :
                info?.updated_at 
                ? `現在の機種マスタの最終更新日　${DateFormatter.str2str(info.updated_at, 'YYYYMMDD_HHmmSS', '/')}`
                : '現在機種マスタは設定されていません。'
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}