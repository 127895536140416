import { v4 } from 'uuid';

type QueItemProps = {
  func: () => Promise<any>,
  onSuccess: (obj: any) => void,
  onError: (obj: any) => void,
  description: string,
  priority?: number,
};

export class QueueItem {

  running = false;

  readonly id: string;
  readonly func: () => Promise<any>;
  readonly onSuccess: (obj: any) => void;
  readonly onError: (obj: any) => void;
  readonly description: string;
  readonly priority: number;

  constructor(
    props: QueItemProps,
  ) {
    const defaultProps = {
      priority: 0,
    };
    const {
      func,
      onSuccess,
      onError,
      description,
      priority,
    } = props;
    this.id = v4();
    this.func = func;
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.description = description;
    this.priority = priority ?? defaultProps.priority;
  }

}