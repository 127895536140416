import React, { useCallback, useMemo } from 'react';
import MasterIconOn from '../../../assets/images/master-icon-on.svg'
import MasterIconOff from '../../../assets/images/master-icon-off.svg'
import UserIconOn from '../../../assets/images/user-icon-on.svg'
import UserIconOff from '../../../assets/images/user-icon-off.svg'
import LedgerIconOn from '../../../assets/images/ledger-icon-on.svg'
import LedgerIconOff from '../../../assets/images/ledger-icon-off.svg'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { RoutingPath } from '../../../routes/routing-path';
import { useOnesDidMount } from '../../../hooks/life-cycle';
import API, { Api } from '../../../api';
import { useContext } from 'react';
import { AppContext } from '../../../App';
export const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useContext(AppContext);

  const selectedLocation = useCallback((...values: (keyof typeof RoutingPath)[]) => {
    const find = values.find((value) => location.pathname.match(RoutingPath[value]));
    return !!find ? 'selected' : '';
  }, [location.pathname]);

  useOnesDidMount(() => {
    const status = window.performance.navigation.type;
    if (status === 1 || API.isLogin) {
      Api.connect().islogin().post({
        ignoreErrorDialog: !API.isLogin,
        onSuccess: (res) => {
          Api.connect().users().detail(res.data.body.data.user_id).get({
            onSuccess: (res) => {
              setUser({ ...res.data.body.data });
            }
          });
        },
        onError: () => {
          // navigate(RoutingPath.login);
        },
      })
    } else {
      navigate(RoutingPath.login);
    }
  })
  return (
    <div className="side_bar">
      <div className="align_center logo_box">
        <Link
          to={RoutingPath.user}
        >
          <h1>
            MAPS&nbsp;PLUS
          </h1>
        </Link>
        <h2>管理メニュー</h2>
      </div>
      <ul className="nav">
        <li className={selectedLocation('user')}>
          <Link
            to={RoutingPath.user}
          >
            <img
              src={UserIconOff}
              alt="ユーザー管理"
              className="off"
            />
            <img
              src={UserIconOn}
              alt="ユーザー管理"
              className="on"
            />
            ユーザー管理
          </Link>
          <ul>
            <li className={selectedLocation('user')}>
              <Link to={RoutingPath.user} >-&nbsp;ユーザー一覧</Link>
            </li>
          </ul>
        </li>
        <li className={user?.is_admin_ope2 ? selectedLocation('model') : 'disabled'}>
          <Link
            to={RoutingPath.model}
          >
            <img
              src={MasterIconOff}
              alt="マスタ管理"
              className="off"
            />
            <img
              src={MasterIconOn}
              alt="マスタ管理"
              className="on"
            />
            マスタ管理
          </Link>
          <ul>
            <li className={selectedLocation('model')}>
              <Link to={RoutingPath.model} >-&nbsp;機種マスタ設定</Link>
            </li>
          </ul>
        </li>
        <li className={user?.is_admin_ope3 ? selectedLocation('flyer', 'logo') : 'disabled'}>
          <Link
            to={RoutingPath.flyer}
          >
            <img
              src={LedgerIconOff}
              alt="帳簿資料管理"
              className="off"
            />
            <img
              src={LedgerIconOn}
              alt="帳簿資料管理"
              className="on"
            />
            帳簿資料管理
          </Link>
          <ul>
            <li className={selectedLocation('flyer')}>
              <Link to={RoutingPath.flyer} >-&nbsp;チラシ入替</Link>
            </li>
            <li className={selectedLocation('logo')}>
              <Link to={RoutingPath.logo}>-&nbsp;製品ロゴ入替</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
