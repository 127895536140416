import { Button } from "../../ui/button/button"
import { Input } from "../../ui/input/input"
import { Radiobutton } from "../../ui/radiobutton/radiobutton"
import './user-edit.dialog.scss'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../App';
import { useValidation } from "../../../hooks/use-validation";
import { AutoPosType } from "../../ui/tooltip/tooltip";
import { ReplaceEisu } from "../../../lib/replace-eisu";
import { Api } from "../../../api";
import { RoutingPath } from "../../../routes/routing-path";
import { SendMailDialog } from "../../dialog/send-mail-dialog";
import { ConfirmValidation } from "../../../lib/validation/validation-factory";

type Props = {
  id?: string;
  callback: () => void,
}

const VALIDATION_POS: AutoPosType = { h: "left", v: "bottom" };
const confirmValidWrap = (origin: string, confirm: string) => ConfirmValidation(origin, confirm, ['パスワードが一致しません']);

export const UserEditDialog = (props: Props) => {
  const { id, callback } = props;
  const isEdit = !!id;
  const { dialogAction, user } = useContext(AppContext);
  const { valid } = useValidation();
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [nameRuby, setNameRuby] = useState('');
  const [familyNameRuby, setFamilyNameRuby] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [company, setCompany] = useState('');
  const [auth, setAuth] = useState(2);
  // const [operationAuth1, setOperationAuth1] = useState(false);
  const [operationAuth2, setOperationAuth2] = useState(false);
  const [operationAuth3, setOperationAuth3] = useState(false);
  const [isActive, setIsActive] = useState(false);
  /* パスワードのリクエスト */
  const passwordRequest = useMemo(() => {
    if (user?.role !== 1) return {};
    if (!password && !passwordConfirm) return {};
    return {
      password,
    }
  }, [password, user, passwordConfirm]);
  /* 操作権限のユーザー権限ごとでの排他処理 */
  const operationAuth = useMemo(() => {
    if (auth === 1) return {
      operationAuth1: true,
      operationAuth2: true,
      operationAuth3: true,
    }
    if (auth === 3) return {
      operationAuth1: false,
      operationAuth2: false,
      operationAuth3: false,
    }

    return {
      operationAuth1: true,
      operationAuth2,
      operationAuth3,
    };

  }, [auth, operationAuth2, operationAuth3]);

  const validPassword = useMemo(() => {
    /* 管理者権限じゃなかったら空 */
    if (user?.role !== 1) return [];
    /* 管理者権限じゃなかったら空 */
    if (!password && !passwordConfirm) return [];
    return [valid(password, 'requireEdit'), valid(password, 'lengthOver8')].flat();
  }, [password]);

  const validPasswordConfirm = useMemo(() => {
    /* 管理者権限じゃなかったら空 */
    if (user?.role !== 1) return [];
    /* 管理者権限じゃなかったら空 */
    if (!password && !passwordConfirm) return [];
    return [valid(passwordConfirm, 'requireEdit'), valid(passwordConfirm, 'lengthOver8'), confirmValidWrap(password, passwordConfirm)].flat();
  }, [passwordConfirm]);

  /* バリデーション:ユーザー編集時専用 */
  const editValidations = useMemo(() => {
    return {
      name: [valid(name, 'require'), valid(name, 'length30')].flat(),
      nameRuby: [valid(nameRuby, 'kana'), valid(nameRuby, 'require'), valid(nameRuby, 'length30')].flat(),
      familyName: [valid(familyName, 'require'), valid(familyName, 'length30')].flat(),
      familyNameRuby: [valid(familyNameRuby, 'kana'), valid(familyNameRuby, 'require'), valid(familyNameRuby, 'length30')].flat(),
      password: validPassword,
      passwordConfirm: validPasswordConfirm
    }
  }, [name, nameRuby, familyName, familyNameRuby, password, passwordConfirm, valid]);

  /* バリデーション(共通) */
  const validations = useMemo(() => {
    const operation = (!Object.values(operationAuth).every((data) => !data) || (auth === 3)) ? '1' : '';
    const edit = isEdit ? editValidations : {};
    return {
      ...edit,
      mail: [valid(mail, 'require'), valid(mail, 'mail'), valid(mail, 'length255')].flat(),
      company: [valid(company, 'require'), valid(company, 'length255')].flat(),
      operationAuth: valid(operation, 'require'),
    }
  }, [isEdit, editValidations, mail, company, operationAuth, auth]);

  /* 全てのバリデーションが通っているか */
  const isDisabled = useMemo(() => {
    const validationValues = Object.values(validations).flat();
    return !!validationValues.length;
  }, [validations]);

  /* 送信ボタン押下時 */
  const handleClickSubmit = useCallback(() => {
    if (isDisabled) return;
    const pushMessage = () => {
      dialogAction.pushMessage({
        title: '完了',
        message: [
          `${isEdit ? '更新' : '登録'}が完了しました。`
        ],
        buttons: [
          {
            label: 'OK', callback: () => {
              callback();
              dialogAction.clear();
            }
          }
        ],
      })
    }
    dialogAction.pushLoading();
    if (!isEdit) {
      Api.connect().users().post({
        data: {
          company_name: company,
          email: mail,
          role: auth,
          is_admin_ope1: operationAuth.operationAuth1 ? 1 : 0,
          is_admin_ope2: operationAuth.operationAuth2 ? 1 : 0,
          is_admin_ope3: operationAuth.operationAuth3 ? 1 : 0,
          pass: `/#${RoutingPath.activate}`,
        },
        onSuccess: () => {
          dialogAction.push({
            title: '',
            element: (
              <SendMailDialog
                callback={() => {
                  dialogAction.clear();
                  callback();
                }}
                message={['追加したメールアドレスへ', '新規登録の案内が届きます。']}
              />
            ),
          })

        },
        popLoading: true,
      })
      return;
    }
    Api.connect().users().detail(id).post({
      data: {
        name,
        name_kana: nameRuby,
        family_name: familyName,
        family_name_kana: familyNameRuby,
        company_name: company,
        role: auth,
        is_stoped: isActive ? 0 : 1,
        is_admin_ope1: operationAuth.operationAuth1 ? 1 : 0,
        is_admin_ope2: operationAuth.operationAuth2 ? 1 : 0,
        is_admin_ope3: operationAuth.operationAuth3 ? 1 : 0,
        ...passwordRequest,
      },
      onSuccess: () => {
        pushMessage();
      }
    })
  }, [id, name, nameRuby, familyName, familyNameRuby, mail, company, auth, isActive, operationAuth2, operationAuth3, dialogAction, callback, isDisabled, passwordRequest]);

  /* 初期値設定 */
  useEffect(() => {
    if (!isEdit || !id) return;
    Api.connect().users().detail(id).get({
      onSuccess: (res) => {
        const data = res.data.body.data;
        setMail(data.email);
        setName(data.name);
        setFamilyName(data.family_name);
        setNameRuby(data.name_kana);
        setFamilyNameRuby(data.family_name_kana);
        setCompany(data.company_name);
        setAuth(data.role);
        setOperationAuth2(!!data.is_admin_ope2);
        setOperationAuth3(!!data.is_admin_ope3);
        setIsActive(!!data.is_valid);
      },
    });
  }, []);

  return (
    <div className="user_edit_dialog">
      <div className="item_wrap">
        <div className="edit_wrap flex">
          <div className="item_head">
            メールアドレス
            <span className="required">必須</span>
          </div>
          <div className="item_cnt">
            {!isEdit ? (
              <Input
                boxSize="large"
                validations={validations.mail}
                value={mail}
                onChange={(e) => setMail(ReplaceEisu(e.target.value))}
                validationAutoPos={VALIDATION_POS}
              />
            ) : (
              <>{mail}</>
            )}
          </div>
        </div>
        <div className="divider" style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
        {!!isEdit && (
          <>
            <div className="edit_wrap flex">
              <div className="item_head" style={{ marginBottom: 0 }}>
                ユーザー名
                <span className="required">必須</span>
              </div>
              <div className="item_cnt">
                <div className="name_wrapper flex">
                  <div className="name_box flex">
                    <span>姓</span>
                    <Input
                      boxSize="large"
                      value={familyName}
                      onChange={(e) => setFamilyName(e.target.value)}
                      validations={editValidations.familyName}
                      validationAutoPos={VALIDATION_POS}
                    />
                  </div>
                  <div className="name_box flex">
                    <span>名</span>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      boxSize="large"
                      validations={editValidations.name}
                      validationAutoPos={VALIDATION_POS}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit_wrap flex">
              <div className="item_head">
                ユーザー名(カナ)
                <span className="required">必須</span>
              </div>
              <div className="item_cnt">
                <div className="name_wrapper flex">
                  <div className="name_box flex">
                    <span>セイ</span>
                    <Input
                      boxSize="large"
                      value={familyNameRuby}
                      onChange={(e) => setFamilyNameRuby(e.target.value)}
                      validations={editValidations.familyNameRuby}
                      validationAutoPos={VALIDATION_POS}
                    />
                  </div>
                  <div className="name_box flex">
                    <span>メイ</span>
                    <Input
                      boxSize="large"
                      value={nameRuby}
                      onChange={(e) => setNameRuby(e.target.value)}
                      validations={editValidations.nameRuby}
                      validationAutoPos={VALIDATION_POS}
                    />
                  </div>
                </div>
              </div>
            </div>
            {(user?.role === 1) && (
              <>
                <div className="divider" style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
                <div className="edit_wrap">
                  <div className="item_head">パスワード</div>
                  <div className="item_cnt">
                    <Input
                      type={'password'}
                      value={password}
                      onChange={(e) => setPassword(ReplaceEisu(e.target.value))}
                      validations={editValidations.password}
                    />
                    <span className="comment">※半角英数８文字以上</span>
                  </div>
                </div>
                <div className="edit_wrap">
                  <div className="item_head">パスワード（確認）</div>
                  <div className="item_cnt">
                    <Input
                      type={'password'}
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(ReplaceEisu(e.target.value))}
                      validations={editValidations.passwordConfirm}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="divider" style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
          </>
        )}
        <div className="edit_wrap flex">
          <div className="item_head">
            会社名
            <span className="required">必須</span>
          </div>
          <div className="item_cnt">
            <Input
              boxSize="large"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              validations={validations.company}
              validationAutoPos={VALIDATION_POS}
            />
          </div>
        </div>
        <div className="divider" style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
        <div className="edit_wrap flex">
          <div className="item_head">
            ユーザー権限
            <span className="required">必須</span>
          </div>
          <div className="item_cnt" style={{ display: 'flex', gap: '1rem' }}>
            <Radiobutton checked={auth === 1} label="管理者" onClick={() => setAuth(1)} />
            <Radiobutton checked={auth === 2} label="一般" onClick={() => setAuth(2)} />
            <Radiobutton checked={auth === 3} label="ゲスト" onClick={() => setAuth(3)} />
          </div>
        </div>
        <div className="divider" style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
        <div className="edit_wrap flex">
          <div className="item_head" style={{ height: '144px', alignItems: 'start' }}>
            操作権限
            <span className="required">必須</span>
          </div>
          <div className="item_cnt" style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: 'max-content', opacity: auth !== 2 ? '0.5' : undefined }}>
            <div style={{ cursor: 'pointer' }} onClick={() => { }}>
              <Input type="checkbox" disabled checked={operationAuth.operationAuth1} validations={validations.operationAuth} style={{ width: 'max-content', marginRight: '4px', cursor: 'pointer' }} validationAutoPos={VALIDATION_POS} />
              ユーザー管理
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setOperationAuth2(!operationAuth2)}>
              <Input type="checkbox" disabled={auth !== 2} checked={operationAuth.operationAuth2} validations={validations.operationAuth} style={{ width: 'max-content', marginRight: '4px', cursor: 'pointer' }} validationAutoPos={VALIDATION_POS} />
              マスタ管理
            </div>
            <div style={{ cursor: 'pointer', }} onClick={() => setOperationAuth3(!operationAuth3)}>
              <Input type="checkbox" disabled={auth !== 2} checked={operationAuth.operationAuth3} validations={validations.operationAuth} style={{ width: 'max-content', marginRight: '4px', cursor: 'pointer' }} validationAutoPos={VALIDATION_POS} />
              帳簿資料管理
            </div>
          </div>
        </div>
        {isEdit && (
          <>
            <div className="divider" style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
            <div className="edit_wrap flex">
              <div className="item_head">
                利用停止日
              </div>
              <div className="item_cnt" style={{ width: 'max-content', display: 'flex' }}>
                {/* <div style={{ cursor: 'pointer' }} onClick={() => setIsActive(!isActive)}>
                  <Input
                    checked={!isActive}
                    type="checkbox"
                    style={{width: 'max-content', marginRight: '4px', cursor: 'pointer'}}
                  />
                  利用停止日
                </div> */}
                <div className="checkbox">
                  <Input
                    id="suspensionDateFlag"
                    type="checkbox"
                    //type="suspensionDateFlag"
                    checked={!isActive}
                    onChange={() => setIsActive(!isActive)}
                  />
                  <label htmlFor="suspensionDateFlag">
                    利用停止
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="button_box">
          <Button label="キャンセル" size="large" color="tertiary" onClick={() => dialogAction.pop()} />
          <Button label={isEdit ? '更新' : '新規招待'} disabled={isDisabled} size="large" onClick={() => handleClickSubmit()} />
        </div>
      </div>
    </div>

  )
}