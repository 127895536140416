import { FileInfoCollection } from "../../../collection/file-info-collection"
import { Ledger } from "../../ui/ledger/ledger"

export const Logo = () => {
  return (
    <Ledger
      title="製品ロゴ"
      apiRoute="logos"
      head={['現在のロゴ', '更新日', '']}
      limit={{
        size: FileInfoCollection.LIMIT.logos,
        siPrefix: 'M',
      }}
    />
  )
}