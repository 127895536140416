import { apiAction, fullUrl, RequestBaseType } from "../../utile";

const detail = (url: string) => (id: string) => {
  const full = `${url}/${id}`;
  return ({
      delete: (param: RequestBaseType<{}, {}>) => apiAction({
          url: full,
          method: 'delete',
          ...param,
      }),
  })
}

export default detail;