import React from "react";
import { Input } from "../input/input";
import './radiobutton.scss';

type RadiobuttonProps = {
  label: string,
  onClick: () => void,
  name?: string,
  checked?: boolean,
  disabled?: boolean,
  className?: string,
};

export const Radiobutton = (props: RadiobuttonProps) => {
  const { label, onClick, className, name, checked, disabled } = props;
  return (
    <div className={`radio_button${className ? ` ${className}` : ''}`}>
      <label className="checked" style={{ display: 'flex' }}>
        <Input
          type="radio"
          name={name}
          onClick={onClick}
          checked={checked}
          disabled={disabled}
          hidden
        />
        <span className="radio_button__check" />
        <span>{label}</span>
      </label>
    </div>
  );
};