import { useCallback, useMemo } from "react";
import { ValidationPattern, ValidationFactory } from '../lib/validation/validation-factory';

export const useValidation = () => {
  const valid = useCallback((value: any, pattern: ValidationPattern) => {
    const validation = ValidationFactory(pattern);
    const result = validation.test(value);
    return !result ? validation.errorMessages : [];
  }, []);

  const validAll = useCallback((value: any, ...patterns: ValidationPattern[]) => {
    const result: string[][] = [];
    patterns.forEach((pattern) => {
      const validation = ValidationFactory(pattern);
      const testResult = validation.test(value);
      if (!testResult) result.push(validation.errorMessages);
    });
    return result.flat();
  }, []);
  return {
    valid,
    validAll,
  }
}